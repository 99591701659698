<template>
  <div class="auth-wrapper auth-v2 text-din-regular">
    <b-row class="auth-inner m-0" style="height: 100vh; background: #fff;">
      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
        style="height: 95%;"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            src="@/assets/images/dsg_career_portal.png"
            class="auth-image"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <RegistrationForm 
        style="height: 95%;"
			/>
      <!-- /Login-->

      <div class="w-100" style="height: 3%; margin-inline:0.5em;">
        <AppFooter class="m-0 p-0 w-100" style="height: 2em;"/>
      </div>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BImg } from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import AppFooter from '@/layouts/components/AppFooter.vue'

import RegistrationForm from './RegistrationForm.vue'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    RegistrationForm,
		AppFooter,
  },
  mixins: [togglePasswordVisibility],

  data() {
    return {
      sideImg: `${this.$bucketUrl}/media/login.svg`,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = `${this.$bucketUrl}/media/login-v2-dark.svg`
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    localStorage.removeItem('current_event')
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

